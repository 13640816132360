import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Signin from './pages/Signin';
import Trial from './pages/Trial';
import Home from './pages/Home';



function App() {
  // const [canAccess, setCanAccess] = useState(true);
  // const toast = useToast()
  // const token = localStorage.getItem('accessToken')
  
  // useEffect(() => {
  //   /** 
  //    * Check if token exists
  //   */
  //   if(!token) {
  //     setCanAccess(false)
  //   }

  //   /**
  //    * Check if token exists and has not expired
  //    */
  //   if(token) {
  //     const tokenJson = parseJWT(token);
  //     if (new Date(tokenJson.exp) < parseInt(new Date().getTime() / 1000)) {
  //       toast({
  //         title: 'Token caducado, vuelve a iniciar sesión',
  //         status: 'error',
  //         isClosable: true,
  //         position: 'top-right',
  //         duration: 1500,
  //         onCloseComplete: () => setCanAccess(false)
  //       })
  //     }
  //   }
  // },[toast, token])

  // if(!canAccess) {
  //   return <Signin />
  // }

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/trial" element={<Trial />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;