// import { useState } from 'react';
import "../App.css";
import { Formik } from "formik";
import { useToast } from '@chakra-ui/react'
import * as Yup from "yup";
// import swal from 'sweetalert';
import VottunLogo from '../vottun.svg'


export default function Signin() {
   const toast = useToast()
   const schema = Yup.object().shape({
      email: Yup.string()
         .required("Email is a required field")
         .email("Invalid email format"),
      password: Yup.string()
         .required("Password is a required field")
         .min(8, "Password must be at least 8 characters"),
   });

   const loginUser = async (credentials) => {   
      const API_URL = process.env.REACT_APP_API_URL;
      const API_ENDPOINT = `${API_URL}/login?lt=10`;

      return fetch(API_ENDPOINT, {
         method: 'POST',
         headers: { "Authorization" : 'Basic ' + btoa(`${credentials.email}:${credentials.password}`) }
      }).then(response => response.json())
   }

   return (                          
      <div className="page-login">
         <Formik
            validationSchema={schema}
            initialValues={{ email: "", password: "" }}
            onSubmit={async (values) =>  {
               const response = await loginUser(values);

               if ('accessToken' in response) {
                  toast({
                     title: 'Bienvenido',
                     status: 'success',
                     isClosable: true,
                     position: 'top-right',
                     duration: 1500,
                     onCloseComplete: (() => {
                        localStorage.setItem('accessToken', response['accessToken'])
                        window.location.href = "/"
                     })
                  })
               } else {
                  toast({
                     title: 'Algo no va bien',
                     description: response.message,
                     status: 'error',
                     isClosable: true,
                     position: 'top-right',
                     duration: 5000
                  })
               }
            }}
         >
            {({
               values,
               errors,
               touched,
               isSubmitting,
               handleChange,
               handleBlur,
               handleSubmit,
            }) => (
               <div className="login">
                  <div className="page-logo">
                     <img src={VottunLogo} alt="Vottun Logo" />
                  </div>
                  <div className="form">
                     <form noValidate onSubmit={handleSubmit}>
                        <input
                           type="email"
                           name="email"
                           onChange={handleChange}
                           onBlur={handleBlur}
                           value={values.email}
                           placeholder="Email"
                           className="form-control inp_text"
                           id="email"
                        />
                        <p className="error">
                           {errors.email && touched.email && errors.email}
                        </p>
                        <input
                           type="password"
                           name="password"
                           onChange={handleChange}
                           onBlur={handleBlur}
                           value={values.password}
                           placeholder="Contraseña"
                           className="form-control"
                        />
                        <p className="error">
                           {errors.password && touched.password && errors.password}
                        </p>
                        <button type="submit" disabled={isSubmitting}>Entrar</button>
                     </form>
                  </div>
               </div>
            )}
         </Formik>
      </div>
   );
}