import React, { useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import VottunLogo from '../vottun.svg'
import { Heading, useToast } from '@chakra-ui/react'
import { Flex, HStack, VStack, Stack, Container, FormControl, Button, Box, Text, FormLabel, Input, FormErrorMessage, Checkbox, Link } from '@chakra-ui/react';
import { SubmitButton } from "formik-chakra-ui";

export default function Home() { 
   const toast = useToast()
   const INITIAL_VALUES = {
      name: '',
      surname: '',
      username: '',
      phone: '',
      city: '',
      cp: '',
      policy: false,
   }

   const [showResponse, setShowResponse] = useState(false);    
   const schema = Yup.object().shape({
      name: Yup.string()
         .required("Name is required")
         .min(3, "Name must have a minimum of 3 characters"),
      username: Yup.string()
         .required("Email is required")
         .min(3, "Email must have a minimum of 3 characters")
         .email('Email must be a valid email'),
      policy: Yup.boolean().oneOf([true],'You must have to accept privacy policy and legal notice before continue')
   });

   const store = async (data) => {
      const API_URL = process.env.REACT_APP_API_URL;
      const API_ENDPOINT = `${API_URL}/public/trial`;

      return fetch(API_ENDPOINT, {
         method: 'POST',
         body: JSON.stringify(data)
      }).then(response => response.json())
   }
  
   return(
      <>
         <div className='page-form'>
            <div className="page-logo">
               <img src={VottunLogo} alt="Vottun Logo" />
            </div>
            <Formik
               initialValues={INITIAL_VALUES}
               enableReinitialize={true}
               validationSchema={schema}
               onSubmit={async (values,{ resetForm }) => {
                  const responseStore = await store(values);

                  if('walletAddress' in responseStore) {
                     setShowResponse(true);
                     resetForm(INITIAL_VALUES);
                     toast({
                        title: 'Request sent successfully',
                        status: 'success',
                        isClosable: true,
                        position: 'top-right',
                        duration: 1500,
                     })
                  }else {
                     if(responseStore.code === 'ERROR_DUPLICATED_ENTRY') {
                        toast({
                           title: 'Existing token',
                           description: 'The token for this user has already been created',
                           status: 'error',
                           isClosable: true,
                           position: 'top-right',
                           duration: 2000,
                           onCloseComplete: (() => resetForm(INITIAL_VALUES))
                        })
                     } else {
                        toast({
                           title: 'Something is wrong',
                           description: responseStore.message,
                           status: 'error',
                           isClosable: true,
                           position: 'top-right',
                           duration: 2000,
                           onCloseComplete: (() => resetForm(INITIAL_VALUES))
                        })
                     }
                  }
               }}
            >
               {({ handleSubmit, values, errors, touched, isSubmitting }) => (
                  <Container maxW="container.lg" p={3}  as="main" minH="74vh">
                     {!showResponse && 
                        <>
                           <Stack p={{ base: 2, md: 2 }} my={{ base: 2, md: 2 }}>
                              <Heading as='h1' size={{base: 'lg', md: 'xl'}} color={'#333'} textAlign={'center'} mb={4}>Start Using The Vottun APIs</Heading>
                              <Text fontSize={'lg'}>In order for you to start working on your Web3 project, we want to offer you a 30-day free trial period so you can start using the Vottun APIs. </Text>
                              <Text fontSize={'lg'}>You need to fill out the form below you will receive an email with a username and an access token.</Text>
                           </Stack>

                           <Stack boxShadow={{ base: '0', md: '2xl' }} p={{ base: 4, md: 12 }} my={{ base: 2, md: 2 }} as="form" onSubmit={handleSubmit} bg={'gray.50'} >
                              <Flex flexDirection={{ base: 'column', lg: 'row' }} align={'space-between'} gap={8} marginBottom={8}>
                                 <VStack spacing='25px' justify={'flex-start'} width={'100%'}>
                                    {/* Row 1 */}
                                    <HStack spacing='15px' justify={'flex-start'} width={'100%'}>
                                       <FormControl isRequired isInvalid={!!errors.name && touched.name}>
                                          <FormLabel noOfLines={1}>Name:</FormLabel>
                                          <Field
                                             as={Input}
                                             variant='flushed'
                                             name="name"
                                             placeholder='Name'
                                             style={{ paddingLeft: '5px' }}
                                          />
                                          {
                                             errors.name && touched.name &&
                                             <FormErrorMessage fontSize='xs'>{errors.name}</FormErrorMessage>
                                          }
                                       </FormControl>

                                       <FormControl isInvalid={!! errors.surname && touched.surname}>
                                          <FormLabel noOfLines={1}>Surname:</FormLabel>
                                          <Field
                                             as={Input}
                                             variant='flushed'
                                             name="surname"
                                             placeholder='Surname'
                                             style={{ paddingLeft: '5px' }}
                                          />
                                          {
                                             errors.surname && touched.surname &&
                                             <FormErrorMessage fontSize='xs'>{errors.name}</FormErrorMessage>
                                          }
                                       </FormControl>
                                    </HStack>

                                    {/* Row 2 */}
                                    <HStack spacing='15px' justify={'flex-start'} width={'100%'}>
                                       <FormControl isRequired isInvalid={!!errors.username && touched.username}>
                                          <FormLabel noOfLines={1}>Email:</FormLabel>
                                          <Field
                                             as={Input}
                                             variant='flushed'
                                             name="username"
                                             placeholder='Email'
                                             style={{ paddingLeft: '5px' }}
                                          />
                                          {
                                             errors.username && touched.username &&    
                                             <FormErrorMessage fontSize='xs'>{errors.username}</FormErrorMessage>     
                                          }
                                       </FormControl>

                                       <FormControl isInvalid={!! errors.phone && touched.phone}>
                                          <FormLabel noOfLines={1}>Phone:</FormLabel>
                                          <Field
                                             as={Input}
                                             variant='flushed'
                                             name="phone"
                                             placeholder='Phone'
                                             style={{ paddingLeft: '5px' }}
                                          />
                                          {
                                             errors.phone && touched.phone &&
                                             <FormErrorMessage fontSize='xs'>{errors.phone}</FormErrorMessage>
                                          }
                                       </FormControl>
                                    </HStack>

                                    {/* Row 3 */}
                                    <HStack spacing='15px' justify={'flex-start'} width={'100%'}>
                                       <FormControl isInvalid={!!errors.city && touched.city}>
                                          <FormLabel noOfLines={1}>City:</FormLabel>
                                          <Field
                                             as={Input}
                                             variant='flushed'
                                             name="city"
                                             placeholder='City'
                                             style={{ paddingLeft: '5px' }}
                                          />
                                          {
                                             errors.city && touched.city &&
                                             <FormErrorMessage fontSize='xs'>{errors.city}</FormErrorMessage>
                                          }
                                       </FormControl>
                                       <FormControl isInvalid={!!errors.cp && touched.cp}>
                                          <FormLabel noOfLines={1}>Postal code:</FormLabel>
                                          <Field
                                             as={Input}
                                             variant='flushed'
                                             name="cp"
                                             placeholder='Postal code'
                                             style={{ paddingLeft: '5px' }}
                                          />
                                          {
                                             errors.cp && touched.cp &&
                                             <FormErrorMessage fontSize='xs'>{errors.cp}</FormErrorMessage>
                                          }
                                       </FormControl>
                                    </HStack>

                                    {/* Row 4 */}
                                    <HStack spacing='15px' justify={'flex-start'} width={'100%'}>
                                       <FormControl display='flex' align='center' flexDirection="column" isInvalid={!!errors.policy && touched.policy} >
                                          <Field as={Checkbox} size='lg' colorScheme='blue' name="policy">
                                             I have read and understood the <Link color="blue.600" href="https://vottun.com/privacy-policy/" isExternal>privacy policy</Link> and <Link color="blue.600" href="https://vottun.com/legal-notice/" isExternal>legal notice</Link> of this website
                                          </Field>
                                          {
                                             errors.policy && touched.policy &&
                                             <FormErrorMessage fontSize='xs'>{errors.policy}</FormErrorMessage>
                                          }
                                       </FormControl>
                                    </HStack>

                                 </VStack>
                              </Flex>

                              <Box textAlign={'center'}>
                                 <SubmitButton
                                    p={8}
                                    px={20}
                                    bgGradient="linear(to-l, #000443, #29AAE2)"
                                    type="submit"
                                    fontSize='sm'
                                    color="white"
                                    disabled={isSubmitting}
                                    _hover={{ bgGradient: "linear(to-l, #29AAE2, #000443)" }}>
                                    Request API KEY
                                 </SubmitButton>
                              </Box>
                           </Stack>
                        </>
                     }
                     {showResponse &&
                     <>
                        <Stack alignItems={'center'}> 
                           <Stack>
                              <Text fontSize={'xl'} textAlign={'center'} mt={12} mb={6}> 
                                 We have sent you an email with the information to access our APIs
                              </Text>

                              {/* <Flex align={'space-between'} marginBottom={12}>                      */}
                                 <Box textAlign={'center'}>
                                    <Button 
                                       p={8}
                                       px={16}
                                       bgGradient="linear(to-l, #000443, #29AAE2)"
                                       fontSize='sm'
                                       color="white"
                                       onClick={() => window.open('https://developers.vottun.tech', '_blank')}
                                       _hover={{ bgGradient: "linear(to-l, #29AAE2, #000443)" }}>
                                       Go to API docs
                                    </Button>
                                 </Box>
                                 
                              {/* </Flex> */}
                           </Stack>
                        </Stack>
                     </>
                     }
                     
                  </Container>
               )}
            </Formik>
         </div>
      </>
   )

}