import React, { useState, useEffect } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import VottunLogo from '../vottun.svg'
import { useToast } from '@chakra-ui/react'
import { Flex, HStack, VStack, Stack, Container, NumberInput, FormControl, Button, Text, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import { SwitchControl, SubmitButton } from "formik-chakra-ui";
import Signin from './Signin';
import parseJWT  from '../utils/parseJWT';

export default function Home() { 
   const [canAccess, setCanAccess] = useState(true);
   const token = localStorage.getItem('accessToken');
   const toast = useToast()
   const INITIAL_VALUES = {
      name: '',
      surname: '',
      username: '',
      phone: '',
      city: '',
      cp: '',
      expirationDays: 0,
      expirationDaysUnlimited: false,
      appName: ''
   }
   const [walletAddress, setWalletAddress] = useState(''); 
   const [customerId, setCustomerId] = useState(''); 
   const [appId, setAppId] = useState(''); 
   const [chainId, setChainId] = useState(''); 
   const [tokenId, setTokenId] = useState(''); 
   const [username, setUsername] = useState(''); 
   const [showResponse, setShowResponse] = useState(false);  

   useEffect(() => {
    /** 
     * Check if token exists
    */
    if(!token) {
      setCanAccess(false)
    }

    /**
     * Check if token exists and has not expired
     */
    if(token) {
      const tokenJson = parseJWT(token);
      if (new Date(tokenJson.exp) < parseInt(new Date().getTime() / 1000)) {
        toast({
          title: 'Token caducado, vuelve a iniciar sesión',
          status: 'error',
          isClosable: true,
          position: 'top-right',
          duration: 1500,
          onCloseComplete: () => setCanAccess(false)
        })
      }
    }
  },[toast, token])

   if(!canAccess) {
      return <Signin />
   }

     
   const schema = Yup.object().shape({
      name: Yup.string()
         .required("El nombre es obligatorio")
         .min(3, "El nombre debe tener mínimo 3 carácteres"),
      username: Yup.string()
         .required("El username es obligatorio")
         .min(3, "El username debe tener mínimo 3 carácteres"),
      appName: Yup.string()
         .required("El nombre de la app es obligatorio")
         .min(3, "El nombre de la app debe tener mínimo 3 carácteres"),
   });

   const redirect = () =>  window.location.href = "/signin";
   const startNewForm = () => setShowResponse(false);
   const store = async (data) => {
      const API_URL = process.env.REACT_APP_API_URL;
      const API_ENDPOINT = `${API_URL}/admin/user/nftapi/web`;

      return fetch(API_ENDPOINT, {
         method: 'POST',
         headers: { "Authorization" : `Bearer ${token}` },
         body: JSON.stringify(data)
      }).then(response => response.json())
   }
   
   return(
      <>
         <div className='page-form'>
            <div className="page-logo">
               <img src={VottunLogo} alt="Vottun Logo" />
            </div>
            <Formik
               initialValues={INITIAL_VALUES}
               enableReinitialize={true}
               validationSchema={schema}
               onSubmit={async (values,{ resetForm }) => {
                  if(values.expirationDaysUnlimited) values.expirationDays = -1;
                  delete values.expirationDaysUnlimited;
                  const responseStore = await store(values);

                  if('walletAddress' in responseStore) {
                     setWalletAddress(responseStore['walletAddress']);
                     setCustomerId(responseStore['customerId']);
                     setAppId(responseStore['appId']);
                     setChainId(responseStore['chainId']);
                     setTokenId(responseStore['token']);
                     setUsername(responseStore['username']);
                     setShowResponse(true);
                     resetForm(INITIAL_VALUES);
                     toast({
                        title: 'Request guardada',
                        status: 'success',
                        isClosable: true,
                        position: 'top-right',
                        duration: 1500,
                     })
                  }else {
                     if(responseStore.code === 'TOKEN_EXCEPTION') {
                        toast({
                           title: 'Token caducado',
                           description: 'Vuelve a iniciar sesión',
                           status: 'error',
                           isClosable: true,
                           position: 'top-right',
                           duration: 2000,
                           onCloseComplete: (() => redirect)
                        })
                     } 
                     else if(responseStore.code === 'ERROR_DUPLICATED_ENTRY') {
                        toast({
                           title: 'Token existente',
                           description: 'El token para este usuario ya ha sido creado',
                           status: 'error',
                           isClosable: true,
                           position: 'top-right',
                           duration: 2000,
                           onCloseComplete: (() => resetForm(INITIAL_VALUES))
                        })
                     } else {
                        toast({
                           title: 'Algo no va bien',
                           description: responseStore.message,
                           status: 'error',
                           isClosable: true,
                           position: 'top-right',
                           duration: 2000,
                           onCloseComplete: (() => resetForm(INITIAL_VALUES))
                        })
                     }
                  }
               }}
            >
               {({ handleSubmit, values, errors, touched, isSubmitting }) => (
                  <Container maxW="container.lg" p={3}  as="main" minH="74vh">
                     {!showResponse && 
                        <Stack boxShadow={{ base: '0', md: '2xl' }} p={{ base: 4, md: 8 }} my={{ base: 2, md: 2 }} as="form" onSubmit={handleSubmit} bg={'gray.50'} >

                           <Flex flexDirection={{ base: 'column', lg: 'row' }} align={'space-between'} gap={8} marginBottom={12}>
                              <VStack spacing='25px' justify={'flex-start'} width={'100%'}>
                                 {/* Row 1 */}
                                 <HStack spacing='15px' justify={'flex-start'} width={'100%'}>
                                    <FormControl isRequired isInvalid={!!errors.name && touched.name}>
                                       <FormLabel noOfLines={1}>Nombre:</FormLabel>
                                       <Field
                                          as={Input}
                                          variant='flushed'
                                          name="name"
                                          placeholder='Nombre'
                                          style={{ paddingLeft: '5px' }}
                                       />
                                       {
                                          errors.name && touched.name &&
                                             
                                          <FormErrorMessage fontSize='xs'>{errors.name}</FormErrorMessage>
                                          
                                       }
                                    </FormControl>

                                    <FormControl isInvalid={!! errors.surname && touched.surname}>
                                       <FormLabel noOfLines={1}>Apellido:</FormLabel>
                                       <Field
                                          as={Input}
                                          variant='flushed'
                                          name="surname"
                                          placeholder='Apellido'
                                          style={{ paddingLeft: '5px' }}
                                       />
                                       {
                                          errors.surname && touched.surname &&

                                          <FormErrorMessage fontSize='xs'>{errors.name}</FormErrorMessage>

                                       }
                                    </FormControl>
                                 </HStack>

                                 {/* Row 2 */}
                                 <HStack spacing='15px' justify={'flex-start'} width={'100%'}>
                                    <FormControl isRequired isInvalid={!!errors.username && touched.username}>
                                       <FormLabel noOfLines={1}>Username:</FormLabel>
                                       <Field
                                          as={Input}
                                          variant='flushed'
                                          name="username"
                                          placeholder='Username'
                                          style={{ paddingLeft: '5px' }}
                                       />
                                       {
                                          errors.username && touched.username &&
                                             
                                          <FormErrorMessage fontSize='xs'>{errors.username}</FormErrorMessage>
                                          
                                       }
                                    </FormControl>

                                    <FormControl isInvalid={!! errors.phone && touched.phone}>
                                       <FormLabel noOfLines={1}>Teléfono:</FormLabel>
                                       <Field
                                          as={Input}
                                          variant='flushed'
                                          name="phone"
                                          placeholder='Teléfono'
                                          style={{ paddingLeft: '5px' }}
                                       />
                                       {
                                          errors.phone && touched.phone &&

                                          <FormErrorMessage fontSize='xs'>{errors.phone}</FormErrorMessage>

                                       }
                                    </FormControl>
                                 </HStack>

                                 {/* Row 3 */}
                                 <HStack spacing='15px' justify={'flex-start'} width={'100%'}>
                                    <FormControl isInvalid={!!errors.city && touched.city}>
                                       <FormLabel noOfLines={1}>Ciudad:</FormLabel>
                                       <Field
                                          as={Input}
                                          variant='flushed'
                                          name="city"
                                          placeholder='Ciudad'
                                          style={{ paddingLeft: '5px' }}
                                       />
                                       {
                                          errors.city && touched.city &&
                                          <FormErrorMessage fontSize='xs'>{errors.city}</FormErrorMessage>
                                       }
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.cp && touched.cp}>
                                       <FormLabel noOfLines={1}>Código Postal:</FormLabel>
                                       <Field
                                          as={Input}
                                          variant='flushed'
                                          name="cp"
                                          placeholder='Código Postal'
                                          style={{ paddingLeft: '5px' }}
                                       />
                                       {
                                          errors.cp && touched.cp &&

                                          <FormErrorMessage fontSize='xs'>{errors.cp}</FormErrorMessage>

                                       }
                                    </FormControl>
                                 </HStack>

                                 {/* Row 4 */}
                                 <HStack spacing='15px' justify={'flex-start'} width={'100%'}>
                                    <HStack pacing='15px' justify={'flex-start'} width={'30%'}>
                                       
                                       <FormControl isRequired isInvalid={!!errors.appName && touched.appName}>
                                          <FormLabel noOfLines={1}>Nombre App:</FormLabel>
                                          <Field
                                             as={Input}
                                             variant='flushed'
                                             name="appName"
                                             placeholder='Nombre App'
                                             style={{ paddingLeft: '5px' }}
                                          />
                                          {
                                             errors.appName && touched.appName &&
                                             <FormErrorMessage fontSize='xs'>{errors.appName}</FormErrorMessage>
                                          }
                                       </FormControl>

                                    </HStack>

                                    <HStack pacing='15px' justify={'flex-start'} width={'70%'}>
                                       <Field name='expirationDays' defaultValue={1}>
                                       {({ field, form }) => (
                                          <FormControl isInvalid={!!errors.expirationDays && touched.expirationDays} width={'20%'}>
                                             <FormLabel>Días disponible</FormLabel>
                                             <NumberInput
                                                variant='flushed'
                                                {...field}
                                                onChange={(val) =>
                                                   form.setFieldValue(field.name, parseInt(val))
                                                }
                                                isDisabled={values.expirationDaysUnlimited}>
                                                <NumberInputField />
                                                <NumberInputStepper>
                                                   <NumberIncrementStepper />
                                                   <NumberDecrementStepper />
                                                </NumberInputStepper>
                                             </NumberInput>
                                             {
                                                errors.expirationDays && touched.expirationDays &&
                                                <FormErrorMessage>{errors.expirationDays}</FormErrorMessage>
                                             }
                                          </FormControl>
                                       )}
                                       </Field>

                                       <FormControl display='flex' align='center' width={'70%'} pl={6} pt={6}>
                                          <SwitchControl name="expirationDaysUnlimited" label="Aplicar token de uso de la API ilimitado" />
                                       </FormControl>

                                    </HStack>
                                 </HStack>
                              </VStack>
                           </Flex>

                           <HStack spacing='15px' justify={'flex-start'} width={'100%'}>
                              <SubmitButton width={{ base: '100%' }}
                                 p={8}
                                 bgGradient="linear(to-l, blue.700, blue.900)"
                                 type="submit"
                                 fontSize='sm'
                                 color="white"
                                 disabled={isSubmitting}
                                 _hover={{ bgGradient: "linear(to-l, blue.900, blue.700)" }}>
                                 Enviar
                              </SubmitButton>
                           </HStack>
                           
                        </Stack>
                     }
                     {showResponse &&
                        <Stack boxShadow={{ base: '0', md: '2xl' }} p={{ base: 4, md: 8 }} my={{ base: 2, md: 2 }} bg={'gray.50'} >
                           <Flex flexDirection={{ base: 'column', lg: 'column' }} align={'space-between'} gap={8} marginBottom={12}>
                              <Text>
                                 Username: <strong>{username}</strong>
                              </Text>
                              <Text>
                                 Wallet Address: <strong>{walletAddress}</strong>
                              </Text>
                              <Text>
                                 Customer Id: <strong>{customerId}</strong>
                              </Text>
                              <Text>
                                 App Id: <strong>{appId}</strong>
                              </Text>
                              <Text>
                                 Chain Id: <strong>{chainId}</strong>
                              </Text>
                              <Text>
                                 Token Id: <strong>{tokenId}</strong>
                              </Text>

                              <Button onClick={startNewForm} p={8}
                                 bgGradient="linear(to-l, blue.700, blue.900)"
                                 fontSize='sm'
                                 color="white"
                                 _hover={{ bgGradient: "linear(to-l, blue.900, blue.700)" }}>
                                 Crear otro
                              </Button>
                           </Flex>
                        </Stack>
                     }
                  </Container>
               )}
            </Formik>
         </div>
      </>
   )

}